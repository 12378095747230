import { createRouter, createWebHistory } from 'vue-router'
import HomeVue from '@/components/HomeVue.vue'
import ContactMain from '@/components/Contact/ContactMain.vue'
import WebSites from '@/components/Websites/WebSites.vue'
import EshopService from '@/components/Eshops/EshopService.vue'


const routes = [
  { path: '/', component: HomeVue },
  { path: '/contact', component: ContactMain },
  { path: '/services/websites', component: WebSites },
  { path: '/services/eshops', component: EshopService },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
